<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage leave category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Leave category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="__add()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    leave category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="__get"
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    v-on:keyup.enter="__get"
                    item-value="value"
                    v-model="search.active"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    :loading="loading"
                    @click="__get"
                    class="btn btn-primary btn-search"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Applicable users</th>
                    <th class="px-3 wrap-column">Auto approval</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>

                <tr
                  v-show="leave_catgories.length > 0"
                  v-for="(leave_category, index) of leave_catgories"
                  :key="leave_category.id"
                >
                  <td scope="row" class="px-3 wrap-column">
                    <a href="#" @click="__edit(leave_category.id)" class="mr-2">
                      {{ leave_category.title }}</a
                    >&nbsp;&nbsp;
                    <i
                      class="fas fa-circle"
                      :class="leave_category.is_active ? 'dot-active' : 'dot-inactive'"
                    ></i>
                  </td>
                  <td class="px-3 py-4 wrap-column">
                    <span v-if="leave_category.applicable_users">
                      <template
                        v-if="getApplicable_users(leave_category.applicable_users).length"
                      >
                        <span
                          class="badge badge-primary ml-2 my-2 text-capitalize"
                          v-for="(user, index) in getApplicable_users(
                            leave_category.applicable_users
                          )"
                          :key="index"
                        >
                          {{ user }}
                        </span>
                      </template>
                      <span class="badge badge-primary ml-2 text-capitalize" v-else>
                        {{ leave_category.applicable_users }}
                      </span>
                    </span>
                  </td>
                  <td class="px-3 pt-4 wrap-column">
                    <span
                      v-if="leave_category.auto_approval"
                      class="badge badge-success">
                        Yes
                    </span>

                    <span
                      v-else
                      class="badge badge-danger"
                      style="background: #F64E60 !important;">
                        No
                    </span>
                  </td>

                  <td class="pr-0 text-center">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                        <!--                                                    <a href="#" class="navi-link" @click="detail(leave_category.id)">-->
                        <!--                                                            <span class="navi-icon">-->
                        <!--                                                              <i class="fas fa-eye"></i>-->
                        <!--                                                            </span>-->
                        <!--                                                      <span class="navi-text"> View </span>-->
                        <!--                                                    </a>-->
                        <!--                                                  </b-dropdown-text>-->
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__edit(leave_category.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__delete(leave_category.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="leave_catgories.length == 0">
                  <td colspan="4" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="leave_catgories.length > 0"
                class="pull-right mt-7"
                @input="__get"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      ref="leave_category"
      @refresh_leave_category="__get"
    ></create-and-update>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import LeaveCategoryService from "@/core/services/leave/leave-category/LeaveCategoryService";

const leaveCategoryService = new LeaveCategoryService();
export default {
  name: "leave-category",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      leave_catgories: [],
      loading: false,
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null",
      },
    };
  },
  mounted() {
    this.__get();
  },
  computed: {},
  methods: {
    getApplicable_users(users) {
      return users.split(",");
    },
    __get() {
      this.loading = true;
      leaveCategoryService
        .paginate(this.search)
        .then((response) => {
          this.leave_catgories = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __add() {
      this.$refs["leave_category"].showModal();
    },
    __edit(id) {
      this.$refs["leave_category"].showModal(id);
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            leaveCategoryService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully Deleted");
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
};
</script>
<style scoped></style>
